:root {
    --color-dark-bg: #0c171c; /*#252525;*/
    --color-dark-fg: #669ab7;
    --color-dark-acc1-blue: #547c95;
    --color-dark-acc2-blue: #425e73;
    --color-dark-acc2-blue-trans: #425e7355;
    --color-dark-acc3-blue: #314151;
    --color-dark-acc3-blue-trans: #31415155;
    --color-dark-acc1-green: #8bc34a;
    --color-dark-acc2-green: #cddc39;
    --color-dark-acc3-green: #00695c;
    
    --dse_bg_img_notext: url(./../img/dse47_large_graphic_no_title.png);
}

.blur {
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
}

.round {
    border-radius: 2rem;
}

.straight {
    border-radius: 0;
}