.layoutContainer {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.header {
    width: 100%;
    height: 75px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url(./assets/img/header-bg.png);
    background-repeat: no-repeat;
    background-position: center;

    border-bottom: 1px solid var(--color-dark-acc3-blue);
}

.logo {
    height: calc(100% / 3);
}

.contentArea {
    flex: 1;
    width: 100%;

    display: flex;
    flex-flow: column;
    gap: 1rem;
}


.gameTitle {
    padding: 2rem 0;
    text-align: center;
}

.gameInfo {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    padding: 1rem;

    background-color: var(--color-dark-acc2-blue-trans);
    text-shadow: var(--color-dark-bg) 1px 1px 4px;

    border-top: 1px solid var(--color-dark-acc2-blue);
    border-bottom: 1px solid var(--color-dark-acc3-blue);
}

.gameInfo > * {
    text-align: justify;
}

.gameInfoCardHeader {
    background-color: var(--color-dark-acc2-blue);
    color: var(--color-dark-acc1-green);
    text-shadow: none;
    text-align: left;
}

.gameDescription {
    padding: 0 2rem;
}

.gameTagline {
    width: 100%;
    padding: 2rem;
    color: var(--color-dark-acc1-green);
    text-align: center;

    align-self: center;
    background: #0c171c44;
}

.footer {
    margin-top: 1rem;
    text-align: center;
}


@media (min-width: 1023px) {
    .gameInfo {
        flex-flow: row;
    }

    .gameInfo > * {
        flex-basis: 100%;
    }
}