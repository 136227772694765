@font-face {
    font-family: 'Exo2';
    src: local('Exo2'), url(./fonts/exo2.ttf) format('truetype');
}


#root {
    height: 100%;
    width: 100%;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-image: url(./assets/img/dse47_large_graphic_no_title.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
